import { Col, Container, Row } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from "react";
import userService from "../../service/UserService";

export const Home = () => {
    const [stats, setStats] = useState([])
    
    useEffect(() => {
      fetchStats()
    }, [])
  
    const fetchStats = async () => {
      const response = await userService.stats() ;
  
      if(response.status==200) {
        setStats(response.data.data)
      } 
    }

    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <div className='top-head-area'>
                            <h5>Dashboard</h5>
                        </div>
                        <Row>
                            <Col md={3}>
                                <div className="dashboard-boxes">
                                    <p>Total Individual User</p>
                                    <div className='dashboard-box-bottom'>
                                        <h3>{ stats.individualUsers }</h3>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="dashboard-boxes business-user">
                                    <p>Total Business User</p>
                                    <div className='dashboard-box-bottom'>
                                        <h3>{ stats.businessUsers }</h3>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="dashboard-boxes individual-referral">
                                    <p>Total Individual Referral</p>
                                    <div className='dashboard-box-bottom'>
                                        <h3>{ stats.individualReferral }</h3>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="dashboard-boxes individual-referral-end">
                                    <p>Total Business Referral</p>
                                    <div className='dashboard-box-bottom'>
                                        <h3>{ stats.businessReferral }</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* pricong graph here */}

                    </div>
                </Container>
            </div>
        </>
    );
};
