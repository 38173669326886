import { createContext } from "react";

console.log(process.env.REACT_APP_API_URL,"REACT_APP_API_URL")
console.log(process.env.REACT_APP_RESOURCE_URL,"REACT_APP_RESOURCE_URL")

export const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  resourceUrl: process.env.REACT_APP_RESOURCE_URL
}

export const MyContext = createContext();
