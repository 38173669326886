import * as Yup from 'yup';
import swal from "sweetalert2";
import moment from 'moment/moment';
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import referralService from "../../service/ReferralService";
import { config } from "../../config/config";
import { Form as FForm, Field, Formik, ErrorMessage, useFormikContext } from "formik";

export const ViewReferral = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [referral, setReferral] = useState({});
  const [referralMedia, setReferralMedia] = useState([]);
  const [referralHistory, setReferralHistory] = useState(new Map());
  const [show, setShow] = useState(false);

  const closeInstallationForm = () => setShow(false);
  const showInstallationForm = () => setShow(true);


  useEffect(() => {
    fetchReferral();
    fetchReferralMedia();
    fetchReferralHistory();
  }, [])

  const fetchReferral = async () => {
    const response = await referralService.getOne(id);
    if (response.status == 200) {
      setReferral(response.data.data)
    }
  }

  const fetchReferralMedia = async () => {
    const response = await referralService.getMedia(id);
    if (response.status == 200) {
      setReferralMedia(response.data.data.list)
    }
  }

  const fetchReferralHistory = async () => {
    const response = await referralService.getHistory(id);
    if (response.status == 200) {
      const map = new Map();
      response.data.data.list.forEach(referral => {
        map.set(referral.status, referral)
      })
      setReferralHistory(map)
    }
  }

  const manageReferralStatus = async (status,data) => {
    const response = await referralService.updateStatus(id, status, data);
    if (response.status == 200) {
      fetchReferral()
      fetchReferralHistory()

      if(status=='INSTALLED') {
        closeInstallationForm()
      }
    }
  }

  const [ downloading, setDownloading ] = useState(false)
  const downloadInvoice = async () => {
    setDownloading(true)
    const response = await referralService.downloadInvoice(id);
    setDownloading(false)
    if (response.status == 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'houdini-referral-invoice.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  }

  const initialValues = {
    installationType: "Electric",
    unitCharges: 100,
    rcbo: 50,
    overLimitCable: 0,
    cableCharges: 0,
    relocationCharges: 20,
    waterValveCharges: 30,
    reconcretingCharges: 50,
    totalCharges: 250,
  };

  const requestFormSchema = Yup.object().shape({
    installationType: Yup.string().required(),
    unitCharges: Yup.number().typeError('Enter numbers only.').required("Please enter amount."),
    rcbo: Yup.number().typeError('Enter numbers only.').required("Please enter amount."),
    overLimitCable: Yup.number().typeError('Enter numbers only.').required("Please enter amount."),
    cableCharges: Yup.number().typeError('Enter numbers only.').required("Please enter amount."),
    relocationCharges: Yup.number().typeError('Enter numbers only.').required("Please enter amount."),
    waterValveCharges: Yup.number().typeError('Enter numbers only.').required("Please enter amount."),
    reconcretingCharges: Yup.number().typeError('Enter numbers only.').required("Please enter amount."),
    totalCharges: Yup.number().typeError('Enter numbers only.').required("Please enter amount."),
  });

  //used to re-calculate the charges for installation
  const CalculationFormikContext = () => {
      const context = useFormikContext();
      React.useEffect(() => {
        const values = context.values;
        const cableCharges = toNumber(values.overLimitCable) * 10;
        const totalCharges = toNumber(values.unitCharges) + toNumber(values.rcbo) + 
                              toNumber(values.relocationCharges) + toNumber(values.waterValveCharges) +
                              toNumber(values.reconcretingCharges) + toNumber(cableCharges);

        context.setFieldValue('cableCharges', cableCharges)
        context.setFieldValue('totalCharges', totalCharges)
      }, [ context.values ]);

    return null;
  };
  
  const toNumber = (value) => {
    return Number(value) || 0
  }

  const handleFormSubmit = async (data) => {
    manageReferralStatus("INSTALLED", data)
  };

  const pay = async () => {
    await swal.fire({
      title: "Make Payment?",
      text: "Are you sure you want to make a payment to this referral?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes"
    }).then( async (result) => {
      if (result.isConfirmed) {
        const response = await referralService.pay(referral._id);
        if (response.status == 200) {
          fetchReferral()
          fetchReferralHistory()
          await swal.fire({title: response.data.message ,icon:'success'})
        }
      }
    });
  }

  return (
    <>
      <div className="dashboard-main-area">
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-2">
              <Col md={12}>
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex referral-details">
                    <h2 className="user-info-heading"><i className="fa fa-angle-left" aria-hidden="true" onClick={() => navigate('/referrals')}></i>Referral Details</h2>
                  </div>
                </div>
              </Col>
              <Row>
                <Col md={6}>
                  <div className="referral-detail-body">
                    <h6>Property Name</h6>
                    <p>{ referral.propertyName }</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="referral-detail-body">
                    <h6>Property Address</h6>
                    <p>{ referral.propertyAddress }</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="referral-detail-body">
                    <h6>Contact Person Name</h6>
                    <p>{ referral.contactPersonName }</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="referral-detail-body">
                    <h6>Additional Details <span>(Optional)</span></h6>
                    <p>{ referral.additionalDetails || 'NA' }</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="referral-detail-body">
                    <h6>Measurement</h6>
                    <p>{ referral.measurement }</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="referral-detail-body">
                    <h6>Property Type</h6>
                    <p>{ referral.propertyType }</p>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="unit-switchboard-area">
                    {
                      referralMedia.map((media, index) => (
                        <div key={index}>
                          <div className="unit-switchboard-inner">
                            <h6>Photo of the {media.title}</h6>
                            <img src={config.resourceUrl.concat(media.path)} />
                            <p>jpg.image</p>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </Col>
                <Col md={6}>
                  <div className="referral-detail-body">
                    <h6>Relocation?</h6>
                    <p>{ referral.relocation ? 'Yes' : 'No' }</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="referral-detail-body">
                    <h6>Is the ground concreate, leveled and flat?</h6>
                    <p>{ referral.isGroundFlat ? 'Yes' : 'No' }</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="referral-detail-body">
                    <h6>Is the old unit accesssible?</h6>
                    <p>{ referral.isOldUnitAccessible ? 'Yes' : 'No' }</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="referral-detail-body">
                    <h6>Do we have clear & clean access in the work area?</h6>
                    <p>{ referral.isWorkAreaClearNAccessible ? 'Yes' : 'No' }</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="referral-detail-body">
                    <h6>Is the cold & hot water ready on the new location & accessible?</h6>
                    <p>{ referral.isHotNColdWaterAccessible ? 'Yes' : 'No' }</p>
                  </div>
                </Col>
              </Row>
              <Col md={12} className="mt-4">
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex referral-details">
                    <h2 className="user-info-heading">Status Management</h2>
                  </div>
                </div>
                <div className="status-management-area">
                  <div className="current-status">
                    {referral.status == 'INITIATED' && <h6>Current Status: <span>Pending Approval <img src={ require("../../assets/images/pending.svg").default} alt="img" /></span></h6>}
                    {referral.status == 'APPROVED' && <h6>Current Status: <span className="approved"> Approved <img src={ require("../../assets/images/approved-tick.svg").default} alt="img" /></span></h6>}
                    {referral.status == 'REJECTED' && <h6>Current Status: <span className="rejected">Rejected <img src={ require("../../assets/images/rejected.svg").default} alt="img" /></span></h6>}
                    {referral.status == 'COMPLETED' && <h6>Current Status: <span className="approved">Completed <img src={ require("../../assets/images/approved-tick.svg").default} alt="img" /></span></h6>}
                  </div>
                  <div className="referral-history-outer">
                    <h6>Referral History</h6>
                    <div className="referral-history-inner">
                      <div className="referral-history">

                        <img src={ require("../../assets/images/approval-green.svg").default} alt="img" />

                        {referral.status == 'INITIATED' && <p>Status: <span>Pending Approval</span></p>}

                        {
                          referralHistory.has('APPROVED') && (
                            <>
                              <p>Status: <span className="approved">Approved</span></p>
                              <h6>Name: {referralHistory.get('APPROVED').title}</h6>
                              <h6>Date: { moment(referralHistory.get('APPROVED').createdAt).format('DD-MM-YYYY') }</h6>
                            </>
                          )
                        }

                        {
                          referralHistory.has('REJECTED') && (
                            <>
                              <p>Status: <span className="rejected">Rejected</span></p>
                              <h6>Name: {referralHistory.get('REJECTED').title}</h6>
                              <h6>Date: { moment(referralHistory.get('REJECTED').createdAt).format('DD-MM-YYYY') }</h6>
                            </>
                          )
                        }

                        {
                          referral.status == 'INITIATED' && (
                            <>
                              <Button type="button" variant="unset" onClick={() => manageReferralStatus('APPROVED',{} )}>Approve</Button>
                              <Button type="button" variant="unset" onClick={() => manageReferralStatus('REJECTED',{} )}>Reject</Button>
                            </>)
                        }
                      </div>

                      <img className="line" src={ require("../../assets/images/line.png")} />

                      <div className={ `referral-history ${!referralHistory.has('INSTALLED') ? 'pending-status' : ''}` }>
                        <img src={ require("../../assets/images/install-green.svg").default} alt="img" />

                        <p>Status: <span className="approved"> Installed</span></p>
                        {!referralHistory.has('INSTALLED') && <p className='pending-btn'>Pending</p>}
                        {
                          referralHistory.has('INSTALLED') && (
                            <>
                              <h6>Name: {referralHistory.get('INSTALLED').title}</h6>
                              <h6>Date: { moment(referralHistory.get('INSTALLED').createdAt).format('DD-MM-YYYY') }</h6>
                              <h6>Expense: ${ referral.charges?.totalCharges }</h6>
                            </>
                          )
                        }

                        {referral.status == 'APPROVED' && <Button type="button" variant="unset" className="pending" onClick={ showInstallationForm }>Install</Button>}
                      </div>

                      <img className="line" src={ require("../../assets/images/line.png")} />

                      <div className={ `referral-history ${!referralHistory.has('COMPLETED') ? 'pending-status' : ''}` }>
                        <img src={ require("../../assets/images/payment.svg").default} alt="img" />
                        <p className="black-text">Status:
                          <span className='paid-gray approved'> 
                            { referral.status!='COMPLETED' ? ' Payment' : referral.transferId ? ' Paid' : ' Completed' } 
                          </span>
                        </p>
                        { !referralHistory.has('COMPLETED') && <p className='pending-btn'>Pending</p> }
                        { referral.status=='INSTALLED' && <Button type="button" variant="unset" onClick={ pay }>Pay</Button>}
                        {
                          referralHistory.has('COMPLETED') && <>
                            <h6>Name: { referralHistory.get('COMPLETED').title}</h6>
                            <h6>Date: { moment(referralHistory.get('COMPLETED').createdAt).format('DD-MM-YYYY') }</h6>
                            <h6>Stripe ID: { referral.transferId || 'NA'}</h6>
                            { !downloading && <Button type="button" variant="unset" className="pending" onClick={ downloadInvoice }>Download Invoice</Button> }
                            { downloading && <p>Downloading......</p> }
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>

              </Col>
              {
                referral.charges && (
                  <>
                    <Col md={12}>
                      <div className="d-flex justify-content-between mb-2">
                        <div className="d-flex referral-details">
                          <h2 className="user-info-heading">Installation Details</h2>
                        </div>
                      </div>
                    </Col>
                    <Row>
                      <Col md={6}>
                        <div className="referral-detail-body">
                          <h6>Installation Type</h6>
                          <p>{ referral.charges.installationType}</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="referral-detail-body">
                          <h6>Unit Charges</h6>
                          <p>${referral.charges.unitCharges}</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="referral-detail-body">
                          <h6>Additional Charges (RCBO)</h6>
                          <p>${referral.charges.rcbo}</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="referral-detail-body">
                          <h6>Cable over 5 metres <span>($10 / meter)</span></h6>
                          <p>{ referral.charges.overLimitCable } meter</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="referral-detail-body">
                          <h6>Cable Charges</h6>
                          <p>${referral.charges.cableCharges}</p>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="referral-detail-body">
                          <h6>Relocation Charges</h6>
                          <p>${referral.charges.relocationCharges}</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="referral-detail-body">
                          <h6>Water Value</h6>
                          <p>${referral.charges.waterValveCharges}</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="referral-detail-body">
                          <h6>Re - Concreting</h6>
                          <p>${referral.charges.reconcretingCharges}</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="referral-detail-body">
                          <h6>Total Charges</h6>
                          <p>${referral.charges.totalCharges}</p>
                        </div>
                      </Col>
                      {
                      referral.quotationAmount &&
                      <Col md={6}>
                        <div className="referral-detail-body">
                          <h6>Proposed Amount</h6>
                          <p>${ referral.quotationAmount }</p>
                        </div>
                      </Col>
                      }
                      <Col md={6}>
                        <div className="referral-detail-body">
                          <h6>Commission</h6>
                          <p>${ referral.earnedCommission > 0 ? referral.earnedCommission : 'NA' }</p>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
            </Row>

          </div>

        </Container>
      </div>

      <Modal show={show} onHide={ closeInstallationForm } centered className="installation-details">
        <Modal.Header closeButton>
          <Modal.Title>Installation Details</Modal.Title>
        </Modal.Header>
        <Formik initialValues={initialValues} validationSchema={requestFormSchema} onSubmit={ handleFormSubmit }>
        {({ values, setFieldValue }) => (
          <FForm>
            <CalculationFormikContext/>
            <Modal.Body>
              <div className="installation-details-inner">
                <h6>Installation Type</h6>
                <div className="type-right">
                  <Field type="hidden" id="installationType" name="installationType" value="Electric"/>
                  <p className={ values.installationType=='Electric' ? 'type-first' : '' } onClick={ () => { 
                    setFieldValue('installationType','Electric') 
                    setFieldValue('unitCharges','100') 
                    } }>Electric
                  </p>

                  <p className={ values.installationType=='Solar' ? 'type-first' : '' } onClick={ () => {
                    setFieldValue('installationType','Solar');
                    setFieldValue('unitCharges','500') 
                    } }>Solar
                  </p>
                </div>
              </div>
              <div className="installation-details-inner">
                <h6>Unit Charges</h6>
                <div className='login-fiels-outer'>
                  <div className='login-fields-inner'>
                    <Form.Group>
                      <Field type="text" id="unitCharges" name="unitCharges"/>
                    </Form.Group>
                    <span>$</span>
                  </div>
                  <ErrorMessage name="unitCharges" component="div" className="text-danger" />
                </div>
              </div>
              <div className="installation-details-inner">
                <h6>Additional Charges (RCBO)</h6>
                <div className='login-fiels-outer'>
                  <div className='login-fields-inner'>
                    <Form.Group>
                      <Field type="text" id="rcbo" name="rcbo" />
                    </Form.Group>
                    <span>$</span>
                  </div>
                  <ErrorMessage name="rcbo" component="div" className="text-danger" />
                </div>
              </div>
              <div className="installation-details-inner">
                <h6>Cable over 5 metres <span>($10.00/ meter)</span></h6>
                <div className='login-fiels-outer'>
                  <div className='login-fields-inner'>
                    <Form.Group>
                      <Field type="text" id="overLimitCable" name="overLimitCable" />
                    </Form.Group>
                  </div>
                  <ErrorMessage name="overLimitCable" component="div" className="text-danger" />
                </div>
              </div>
              <div className="installation-details-inner">
                <h6>Cable Charges</h6>
                <div className='login-fiels-outer'>
                  <div className='login-fields-inner'>
                    <Form.Group>
                      <Field type="text" id="cableCharges" name="cableCharges" readOnly/>
                    </Form.Group>
                    <span>$</span>
                  </div>
                  <ErrorMessage name="cableCharges" component="div" className="text-danger" />
                </div>
              </div>
              <div className="installation-details-inner">
                <h6>Relocation</h6>
                <div className='login-fiels-outer'>
                  <div className='login-fields-inner'>
                    <Form.Group>
                      <Field type="text" id="relocationCharges" name="relocationCharges" />
                    </Form.Group>
                    <span>$</span>
                  </div>
                  <ErrorMessage name="relocationCharges" component="div" className="text-danger" />
                </div>
              </div>
              <div className="installation-details-inner">
                <h6>Water Value</h6>
                <div className='login-fiels-outer'>
                  <div className='login-fields-inner'>
                    <Form.Group>
                      <Field type="text" id="waterValveCharges" name="waterValveCharges" />
                    </Form.Group>
                    <span>$</span>
                  </div>
                  <ErrorMessage name="waterValveCharges" component="div" className="text-danger" />
                </div>
              </div>
              <div className="installation-details-inner">
                <h6>Re - Concreting</h6>
                <div className='login-fiels-outer'>
                  <div className='login-fields-inner'>
                    <Form.Group>
                      <Field type="text" id="reconcretingCharges" name="reconcretingCharges" />
                    </Form.Group>
                    <span>$</span>
                  </div>
                  <ErrorMessage name="reconcretingCharges" component="div" className="text-danger" />
                </div>
              </div>
              <div className="installation-details-inner">
                <h6>Total Charges</h6>
                <div className='login-fiels-outer'>
                  <div className='login-fields-inner'>
                    <Form.Group>
                      <Field type="text" id="totalCharges" name="totalCharges" />
                    </Form.Group>
                    <span>$</span>
                  </div>
                  <ErrorMessage name="totalCharges" component="div" className="text-danger" readOnly/>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </Modal.Footer>
        </FForm>
          )}
        </Formik>
      </Modal>
    </>
  )
};