import "./App.css";
import "./assets/css/style.css";

import { Login } from "./components/login/Login";
import { Home } from "./components/dashboard/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ViewUser } from "./components/users/viewUser.js";
import { ChangePassword } from "./components/changePassword/ChangePassword";
import { ViewUsers } from './components/users/viewUsers.js'
import { CreateSubAdmin } from './components/users/createSubAdmin.js'
import { ViewSubAdmins } from './components/users/viewSubAdmins.js'
import SecuredRoute from "./util/SecuredRoute.js";
import { ViewReferrals } from "./components/referral/viewReferrals.js";
import { ViewReferral } from "./components/referral/viewReferral.js";
import { AppProvider } from "./util/AppContext.js";
import UnsecuredRoute from "./util/UnsecuredRoute.js";
import { PageNotFound } from "./components/Notfound.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <UnsecuredRoute><Login /></UnsecuredRoute>,
  },
  {
    path: "/login",
    element: <UnsecuredRoute><Login /></UnsecuredRoute>,
  },
  {
    path: "/home",
    element: <SecuredRoute><Home /></SecuredRoute>,
  },
  {
    path: "/users",
    element: <SecuredRoute><ViewUsers /></SecuredRoute>,
  },
  {
    path: "/users/:id",
    element: <SecuredRoute><ViewUser /></SecuredRoute>,
  },
  {
    path: "/referrals",
    element: <SecuredRoute><ViewReferrals /></SecuredRoute>,
  },
  {
    path: "/create-sub-admin",
    element: <SecuredRoute><CreateSubAdmin /></SecuredRoute>,
  },
  {
    path: "/view-sub-admins",
    element: <SecuredRoute><ViewSubAdmins /></SecuredRoute>,
  },
  {
    path: "/referrals/:id",
    element: <SecuredRoute><ViewReferral /></SecuredRoute>,
  },
  {
    path: "/change-password",
    element: <SecuredRoute><ChangePassword /></SecuredRoute>,
  },
  {
    path: "*",
    element: <PageNotFound/>,
  },
]);

function App() {
  return (
    <>
      <AppProvider>
        <RouterProvider router={router}/>
      </AppProvider>
    </>
  )
}

export default App;
