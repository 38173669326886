import * as Yup from 'yup';
import swal from "sweetalert";
import userService from '../../service/UserService'
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { Form as FForm, Field, Formik, ErrorMessage } from "formik";
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

export const CreateSubAdmin = () => {
  const navigate = useNavigate()
  const [ viewPassword, setViewPassword ]= useState({ new: true, confirm : true });

  const initialValues = { name: '', email:'', password: '', confirmPassword : '' };
  const requestFormSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.').trim(),
    email: Yup.string().required('Email is required.').email('Please enter a valid email.').trim(),
    password: Yup.string()
      .required("Password is required.")
      .min(8, 'Password must be at least 8 characters long').trim(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required("Password Confirmation is required.").trim(),
  });

  const handleFormSubmit = async (data) => {
    const response = await userService.create(data);
    if(response.status==201) {
      swal({ icon: 'info', text: 'Sub-Admin created successfully.', button: "OK" })
      .then(() => {
        navigate("/view-sub-admins")
      });
    }
 
  };

  return (
    <>
      <div className="dashboard-main-area min-height password-outer">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xl={4}>
              <div className="login-content-area">
                <h2 >Create Sub-Admin</h2>
                <Formik initialValues={initialValues} validationSchema={requestFormSchema} onSubmit={handleFormSubmit}>
                  {() => (
                    <FForm>
                      <div className='login-fiels-outer'>
                        <div className='login-fields-inner'>
                          <Form.Group>
                            <Field type="text" placeholder="Name" id="name" name="name" />
                          </Form.Group>
                        </div>
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </div>

                      <div className='login-fiels-outer'>
                        <div className='login-fields-inner'>
                          <Form.Group>
                            <Field type="email" placeholder="Email" id="email" name="email" />
                          </Form.Group>
                        </div>
                        <ErrorMessage name="email" component="div" className="text-danger" />
                      </div>

                      <div className='login-fiels-outer'>
                        <div className='login-fields-inner'>
                          <Form.Group>
                            <Field type={ viewPassword.new ? 'password' : 'text' } placeholder="Password" id="password" name="password" />
                          </Form.Group>
                          { viewPassword.new ?
                            <img src={ require("../../assets/images/eye-icon.svg").default}  alt='img' onClick={ () =>  setViewPassword({ ...viewPassword, new:!viewPassword.new }) }/> :
                            <img src={ require("../../assets/images/eye-slash.svg").default}  alt='img' onClick={ () =>  setViewPassword({ ...viewPassword, new:!viewPassword.new }) }/>
                          }
                        </div>
                        <ErrorMessage name="password" component="div" className="text-danger" />
                      </div>

                      <div className='login-fiels-outer'>
                        <div className='login-fields-inner'>
                          <Form.Group>
                            <Field type={ viewPassword.confirm ? 'password' : 'text' } placeholder="Confirm Password" id="confirmPassword" name="confirmPassword" />
                          </Form.Group>
                          { viewPassword.confirm ?
                            <img src={ require("../../assets/images/eye-icon.svg").default}  alt='img' onClick={ () =>  setViewPassword({ ...viewPassword, confirm:!viewPassword.confirm }) }/> :
                            <img src={ require("../../assets/images/eye-slash.svg").default}  alt='img' onClick={ () =>  setViewPassword({ ...viewPassword, confirm:!viewPassword.confirm }) }/>
                          }
                        </div>
                        <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                      </div>
                      <Button className="w-50" variant="primary" type="submit">
                        Submit
                      </Button>
                    </FForm>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};