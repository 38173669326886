import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppContext } from './AppContext';
import Header from '../common/Header';
import Sidebar from '../common/Sidebar';

const SecuredRoute = ({ children }) => {

  const adminOnly = [ "/create-sub-admin","/view-sub-admins" ]
  const location = useLocation();
  const { isAuthenticated,user  } = useContext(AppContext);
  
  if(adminOnly.includes(location.pathname) && user.role!='ADMIN') {
    console.log("not admin redirecting.....")
    return <Navigate to="/"/>; 
  }

  return isAuthenticated ?  
    <>
      <Header />
      <Sidebar />
      { children } 
    </> 
    
    : 
    
    <Navigate to="/login"/>; 
};

export default SecuredRoute;
