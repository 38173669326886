import client from './AxiosClient'

const referralService = {
    filter : (params) => client.get("/referral", { params }),
    updateStatus : (referralId,status,body) => client.put(`/referral/status/${referralId}?status=${status}`,body),
    getOne : (referralId) => client.get(`/referral/${referralId}`),
    getMedia : (referralId) => client.get(`/media/${referralId}`),
    getHistory : (referralId) => client.get(`/referral/history/${referralId}`),
    pay : (referralId) => client.post(`/stripe/pay/${referralId}`),
    downloadInvoice : (referralId) => client.get(`/referral/invoice/${referralId}`, { responseType: 'blob'}),
}


export default referralService;