import axios from "axios";
import swal from "sweetalert";
import { config } from "../config/config";

const client = axios.create({
    baseURL: config.apiUrl,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json'
    },
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('houdini-admin-auth-token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Add token to headers
    }
    return config;
  },
  (error) => {
    console.log(error,"axios request intercept error")
    return error;
  }
)

// Add a response interceptor
client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log(error.response)
    const message = error.response?.data?.message || "Something went wrong!"
    swal({ icon: 'error', text: message, button: "OK" });

    if(error.response.status==401 || error.response.status==403) {
      window.location.href="/"
      localStorage.clear();
    }

    return error;
  }
);

export default client;