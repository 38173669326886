import * as Yup from 'yup';
import swal from "sweetalert";
import userService from '../../service/UserService'
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { Form as FForm, Field, Formik, ErrorMessage } from "formik";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export const ChangePassword = () => {
  const navigate = useNavigate()

  const [ viewPassword, setViewPassword ]= useState({ old: true, new: true, confirm : true });
  const initialValues = { oldPassword: '', newPassword: '', confirmPassword : '' };
  const requestFormSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Current password is required.').trim(),
    newPassword: Yup.string()
      .required("New password is required.")
      .min(8, 'Password must be at least 8 characters long')
      .notOneOf([Yup.ref('oldPassword')], 'Old and new passwords can not be same').trim(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required("Password Confirmation is required.").trim(),
  });

  const handleFormSubmit = async (data) => {
    console.log(data, "kjhkhkj")
    const response = await userService.changePassword(data);
    if(response.status==200) {
      swal({ icon: 'info', text: 'Password updated successfully.', button: "OK" })
      .then(() => {
        navigate("/")
      });
    }
 
  };

  return (
    <>
      <div className="dashboard-main-area min-height password-outer">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xl={4}>
              <div className="login-content-area">
                <img src={ require ("../../assets/images/password-image.png")}/>
                <h2 >Change Password</h2>
                <Formik initialValues={initialValues} validationSchema={requestFormSchema} onSubmit={handleFormSubmit}>
                  {() => (
                    <FForm>
                      <div className='login-fiels-outer'>
                        <div className='login-fields-inner'>
                          <Form.Group>
                            <Field type={ viewPassword.old ? 'password' : 'text' } placeholder="Old Password" id="oldPassword" name="oldPassword" />
                          </Form.Group>
                          { viewPassword.old ?
                            <img src={ require("../../assets/images/eye-icon.svg").default}  alt='img' onClick={ () =>  setViewPassword({ ...viewPassword, old:!viewPassword.old }) }/> :
                            <img src={ require("../../assets/images/eye-slash.svg").default}  alt='img' onClick={ () =>  setViewPassword({ ...viewPassword, old:!viewPassword.old }) }/>
                          }
                        </div>
                        <ErrorMessage name="oldPassword" component="div" className="text-danger" />
                      </div>

                      <div className='login-fiels-outer'>
                        <div className='login-fields-inner'>
                          <Form.Group>
                            <Field type={ viewPassword.new ? 'password' : 'text' } placeholder="Current Password" id="newPassword" name="newPassword" />
                          </Form.Group>
                          { viewPassword.new ?
                            <img src={ require("../../assets/images/eye-icon.svg").default}  alt='img' onClick={ () =>  setViewPassword({ ...viewPassword, new:!viewPassword.new }) }/> :
                            <img src={ require("../../assets/images/eye-slash.svg").default}  alt='img' onClick={ () =>  setViewPassword({ ...viewPassword, new:!viewPassword.new }) }/>
                          }
                        </div>
                        <ErrorMessage name="newPassword" component="div" className="text-danger" />
                      </div>
                      <div className='login-fiels-outer'>
                        <div className='login-fields-inner'>
                          <Form.Group>
                            <Field type={ viewPassword.confirm ? 'password' : 'text' } placeholder="Confirm Password" id="confirmPassword" name="confirmPassword" />
                          </Form.Group>
                          { viewPassword.confirm ?
                            <img src={ require("../../assets/images/eye-icon.svg").default}  alt='img' onClick={ () =>  setViewPassword({ ...viewPassword, confirm:!viewPassword.confirm }) }/> :
                            <img src={ require("../../assets/images/eye-slash.svg").default}  alt='img' onClick={ () =>  setViewPassword({ ...viewPassword, confirm:!viewPassword.confirm }) }/>
                          }
                        </div>
                        <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                      </div>
                      <Button className="w-50" variant="primary" type="submit">
                        Submit
                      </Button>
                    </FForm>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};