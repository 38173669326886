import moment from 'moment';
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import userService from "../../service/UserService";
import { Col, Container, Dropdown, Row, Table, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

export const ViewSubAdmins = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([])
  const [pagination, setPagination] = useState({ pageNumber:1, pageSize:10, pageRange:5, totalCount:0 })
  
  useEffect(() => {
    fetchUsers()
  }, [ pagination.pageNumber ])

  const fetchUsers = async () => {
    const params = { role : 'SUB_ADMIN', page : pagination.pageNumber - 1, size : pagination.pageSize }
    const response = await userService.filter(params) ;

    if(response.status==200) {
      setUsers(response.data.data.list)
      setPagination({
        ...pagination , 
        totalCount : response.data.data.count
      })
    } 
  }

  const handlePageChange = (pageNumber) => {
    setPagination({
      ...pagination , 
      pageNumber : pageNumber 
    })
  };

  const onUserStatusChange = async (index) => {
    const userToUpdate = users[index];
    const response = await userService.updateStatus(userToUpdate._id) ;
    if(response.status==200) {
      const userToUpdate = users[index];
      users[index] = { ...userToUpdate, active : !userToUpdate.active };
      setUsers([ ...users ])
    }
  }

  return (
    <>
      <div className="dashboard-main-area">
        <Container fluid>
          <div className="dashboard-area-heading management-heading">

            <Row className="row justify-content-center align-items-center mb-2">
              <Col md={12}>
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">View Sub Admin</h2>
                  </div>
                  <Button type="button" variant="unset" className='create-admin' onClick={() => navigate('/create-sub-admin')}><i className="fa fa-plus" aria-hidden="true"></i> Create Sub Admin</Button>
                </div>
              </Col>
            </Row>

            <Row className="pb-4 justify-content-center">
              <Col md={12}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Creation Date</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                        {
                          users.map((user,index) => (
                            <tr key={ index }>
                                <td>{ moment(user.createdAt).format('DD-MM-YYYY') }</td>
                                <td>{ user.name }</td>
                                <td>{ user.email }</td>

                                <td className={user.active ? 'under-dropdown status-active' : 'under-dropdown status-inactive'}>
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      { user.active ? 'Active' : 'Inactive' }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={ () => onUserStatusChange(index) }>
                                      { user.active ? 'Deactivate' : 'Activate' }
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                            </tr>
                          ))
                        }

                        {
                        pagination.totalCount==0 &&

                        <tr className="no-dat-found-box">
                            <td colSpan={10}>
                              <img src={ require("../../assets/images/no-data-bg.gif")} alt="no-data-image" />
                              <h3 className="text-center">
                                No data found
                              </h3>
                            </td>
                        </tr>
                        }
                    </tbody>
                  </Table>
                  
                    <Pagination
                      activePage={pagination.pageNumber}
                      itemsCountPerPage={pagination.pageSize}
                      totalItemsCount={pagination.totalCount}
                      pageRangeDisplayed={pagination.pageRange}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
