import swal from "sweetalert2";
import React, { createContext, useState } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const user = localStorage.getItem('houdini-admin-auth')
    return user ? JSON.parse(user) : null;
  });
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage.getItem('houdini-admin-auth-token');
    return !!token;
  });
  
  const login = (token,userData) => {
    console.log("logging in......")
    localStorage.setItem('houdini-admin-auth-token',token);
    localStorage.setItem('houdini-admin-auth',JSON.stringify(userData));
    setIsAuthenticated(true);
    setUser(userData);
  };
  const logout = async () => {
    console.log("logging out......")
    await swal.fire({
      title: "Log Out?",
      text: "Are you sure to log out ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear()
        setIsAuthenticated(false);
        setUser(null);        
      }
    });
  };

  return (
    <AppContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
