import * as Yup from 'yup';
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Form as FForm, Field, Formik, ErrorMessage } from "formik";
import userService from '../../service/UserService'
import { AppContext } from '../../util/AppContext';

export const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AppContext)

  const [ viewPassword, setViewPassword ]= useState(true);
  const initialValues = { email: '', password: '', role : 'ADMIN' };
  const requestFormSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email.').required("Email is required.").trim(),
    password: Yup.string().required("Password is required.").trim(),
    role : Yup.string()
  });

  const handleFormSubmit = async (data) => {
    const response = await userService.login(data);
    if(response.status==200) {
      login(response.data.data.token, response.data.data.user);
      navigate("/home")
    }
  };

  return (
    <>
      <div className="login-outer-area">
        <div className="login-area">
          <Container>
            <Row className="justify-content-center">
              <Col md={5}>
                <div className="login-content-area">
                  <img src={ require("../../assets/images/logo.png")} />
                  <h2>Login </h2>
                  <Formik initialValues={initialValues} validationSchema={requestFormSchema} onSubmit={handleFormSubmit}>
                    {() => (
                      <FForm>
                        <div className='login-fiels-outer'>
                          <div className='login-fields-inner'>
                            <Form.Group>
                              <Field type="email" placeholder="Email" id="email" name="email" />
                            </Form.Group>
                            <img src={ require("../../assets/images/emaili-icon.svg").default} />
                          </div>
                          <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>


                        <div className='login-fiels-outer'>
                          <div className='login-fields-inner'>
                            <Form.Group>
                              <Field type={ viewPassword ? 'password' : 'text' } placeholder="Password" id="password" name="password" />
                            </Form.Group>
                            { viewPassword ?
                              <img src={ require("../../assets/images/eye-icon.svg").default}  alt='img' onClick={ () =>  setViewPassword(!viewPassword) }/> :
                              <img src={ require("../../assets/images/eye-slash.svg").default}  alt='img' onClick={ () =>  setViewPassword(!viewPassword) }/>
                            }
                          </div>
                          <ErrorMessage name="password" component="div" className="text-danger" />
                        </div>

                        <Button className="w-50" variant="primary" type="submit">
                          Login
                        </Button>
                      </FForm>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};
