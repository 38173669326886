import client from './AxiosClient'

const userService = {
    login : (data) => client.post("/auth/login",data),
    filter : (params) => client.get("/users", { params }),
    create : (data) => client.post("/users", data),
    updateStatus : (userId) => client.put(`/users/status/${userId}`),
    changePassword : (data) => client.put(`/users/change-password`, data),
    getOne : (userId) => client.get(`/users/${userId}`),
    stats : () => client.get(`/admin/dashboard`),
}


export default userService;