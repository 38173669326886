import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from './AppContext';

const UnsecuredRoute = ({ children }) => {
  const { isAuthenticated  } = useContext(AppContext);
  console.log("isAuthenticated", isAuthenticated)
  return isAuthenticated ? <Navigate to="/home"/> : children; 
};

export default UnsecuredRoute;
