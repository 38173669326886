import moment from 'moment';
import React, { useState, useEffect } from "react";
import { Button, Col, Container, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Pagination from "react-js-pagination";
import referralService from "../../service/ReferralService";
import { Link } from 'react-router-dom';

export const ViewReferrals = () => {
  const [type, setType] = useState('INDIVIDUAL')
  const [referrals, setReferrals] = useState([])
  const [pagination, setPagination] = useState({ pageNumber:1, pageSize:10, pageRange:5, totalCount:0 })
  
  useEffect(() => {
    fetchReferrals()
  }, [ type, pagination.pageNumber ])

  const fetchReferrals = async () => {
    const params = { type : type, page : pagination.pageNumber - 1, size : pagination.pageSize }
    const response = await referralService.filter(params) ;

    if(response.status==200) {
      setReferrals(response.data.data.list)
      setPagination({
        ...pagination , 
        totalCount : response.data.data.count
      })
    } 
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View
    </Tooltip>
  );

  const onTypeChange = (type) => {
    setType(type);
    setPagination({
      ...pagination , 
      pageNumber : 1 
    })
  }

  const handlePageChange = (pageNumber) => {
    setPagination({
      ...pagination , 
      pageNumber : pageNumber 
    })
  };

  const statusClassMap = {
    'INITIATED': 'awaiting-payment',
    'APPROVED' : 'accepted',
    'REJECTED' : 'rejected',
    'INSTALLED' : 'installed',
    'COMPLETED' : 'completed'    
  }

  return (
    <>
      <div className="dashboard-main-area">
        <Container fluid>
          <div className="dashboard-area-heading management-heading">

            <Row className="row justify-content-center align-items-center mb-2">
              <Col md={12}>
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Referral Management</h2>
                  </div>

                  <div className="user-list-top-btn">
                    <Button type="button" variant="unset" className={ type=='INDIVIDUAL' ? 'individual-btn' : 'individual-btn active' } onClick={ () => onTypeChange('INDIVIDUAL') }><i className="fas fa-user"></i> Individual</Button>
                    <Button type="button" variant="unset" className={ type=='BUSINESS' ? 'individual-btn' : 'individual-btn active' } onClick={ () => onTypeChange('BUSINESS') }><i className="fas fa-users"></i> Business</Button>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="pb-4 justify-content-center">
              <Col md={12}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Creation Date</th>
                        {
                          type=='BUSINESS' &&
                          <th>Business Name</th>  
                        }
                        <th>Property Name</th>
                        <th>Address </th>
                        <th>Referred By</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      
                        {
                          referrals && referrals.map((referral,index) => (
                            <tr key={ index }>
                              <td>{ moment(referral.createdAt).format('DD-MM-YYYY') }</td>
                              {
                                type=='BUSINESS' &&
                                <td>{ referral.referredBy?.businessName }</td>  
                              }
                              <td>{ referral.propertyName }</td>
                              <td>{ referral.propertyAddress }</td>
                              <td>
                                <p>{ referral.referredBy?.name || referral.referredBy?.businessContactPerson }</p>
                                <p>{ referral.referredBy?.contactNumber }</p>
                              </td>
                              <td className={ statusClassMap[referral.status] }>
                                <Button type="button" variant="unset">{ referral.status }</Button>
                              </td>
                              <td>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                              >
                                <Link to={`/referrals/${referral._id}`}>
                                  <div className="view-btn-main" style={{ cursor: 'pointer' }}>
                                    < i className="fa-solid fa-eye"></i>
                                  </div>
                                </Link>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))
                        }
                        {
                          pagination.totalCount==0 &&

                          <tr className="no-dat-found-box">
                            <td colSpan={10}>
                              <img src={ require("../../assets/images/no-data-bg.gif")} alt="no-data-image" />
                              <h3 className="text-center">
                                No data found
                              </h3>
                            </td>
                          </tr>
                        }
                    </tbody>
                  </Table>

                  <Pagination
                      activePage={pagination.pageNumber}
                      itemsCountPerPage={pagination.pageSize}
                      totalItemsCount={pagination.totalCount}
                      pageRangeDisplayed={pagination.pageRange}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
