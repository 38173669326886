import { Container } from "react-bootstrap";


const Header = () => {
  
  return (
    <>
      <div className="admin-header">
        <Container fluid>
          <div className="header-right">
            <div className="position-reltive under-search-header">
              
            </div>
            
          </div>
        </Container>
      </div>
    </>
  );
};
export default Header;
