import moment from "moment/moment";
import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import userService from "../../service/UserService";

export function ViewUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState({});

  useEffect(() => {
    fetchUser()
  }, [])

  const fetchUser = async () => {
    const response = await userService.getOne(id);
    if (response.status == 200) {
      setUser(response.data.data)
    }
  }

  return (
    <>
      <div className="dashboard-main-area">
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="pb-4 mb-2 justify-content-center">
              <Col md={12}>
                <h2 className="user-info-heading">
                  <i className="fa fa-angle-left" aria-hidden="true" onClick={() => navigate('/users')}></i> User Management
                </h2>
              </Col>
              <Row className="pb-4">
                <Col md={12}>
                  <div className="user-info-area" >
                    { user ?
                      <>
                        <Row className="mb-4">
                          <Col md={4}>
                            <h6>Creation Date </h6>
                            <p>{ moment(user.createdAt).format('DD-MM-YYYY') }</p>
                          </Col>
                          <Col md={4}>
                            <h6>User Type</h6>
                            <p> { user.role }</p>
                          </Col>
                          {user.businessName &&
                            <Col md={4}>
                              <h6>Business Name </h6>
                              <p>{ user.businessName }</p>
                            </Col>
                          }
                          <Col md={4}>
                            <h6> { user.name ? 'Name' : 'Contact Person Name' } </h6>
                            <p>{ user.name || user.businessContactPerson }</p>
                          </Col>

                          <Col md={4}>
                            <h6>Email</h6>
                            <p> { user.email }</p>
                          </Col>

                          <Col md={4}>
                            <h6>Contact Number</h6>
                            <p>{ user.contactNumber } </p>
                          </Col>
                          <Col md={4}>
                            <h6>Active ?</h6>
                            <p> { user.active ? 'Yes' : 'No' }</p>
                          </Col>
                          <Col md={4}>
                            <h6>Stripe ID</h6>
                            <p>{ user.stripeConnectId || 'NA' }</p>
                          </Col >
                          <Col md={4}>
                            <h6>Stripe Status</h6>
                            <p>{ user.stripeStatus || 'NA' }</p>
                          </Col>
                        </Row>

                      </>
                      :
                      <div className="no-dat-found-box">
                        <img src={ require("../../assets/images/no-data.svg").default} alt="da" />
                        <h3 className="text-center text-light">
                          No data found
                        </h3>
                      </div>}
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}